import "./App.css";
import Home from "./home";

function App() {
  return (
    <div>
      <Home />
      <p style={{ textAlign: "center", marginBottom: "20px" }}>
        网站备案号: 苏ICP备2024112941号-2
      </p>
    </div>
  );
}

export default App;
