import "./index.scss";
import logo from "../images/logo.svg";
import clientsImg from "../images/clients.png";
import goodsImg from "../images/goods.png";
import quoteImg from "../images/quote.png";
import helpImg from "../images/help.png";
const FirstScreen = () => {
  return (
    <div className="first-screen">
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
        <div className="header-right">
          <div className="menu-item">首页</div>
          <div className="menu-item">联系我们</div>
        </div>
      </header>
      <div className="first-screen-content">
        <div className="left">
          <h1>图册宝</h1>
          <h3>图册管理专家</h3>
          <p>专属您的产品图册, 帮助您实现产品管理、产品宣传、产品报价</p>
          {/* <div className="btn">了解更多</div> */}
        </div>
        <div className="right"></div>
      </div>
      <div className="data-list">
        <div className="data-item">
          <div className="data-value">20+</div>
          <div className="data-label">店铺数量</div>
        </div>
        <div className="seperation"></div>
        <div className="data-item">
          <div className="data-value">3000+</div>
          <div className="data-label">用户数量</div>
        </div>
        <div className="seperation"></div>
        <div className="data-item">
          <div className="data-value">98%</div>
          <div className="data-label">好评率</div>
        </div>
      </div>
    </div>
  );
};

const service = [
  {
    title: "商品管理",
    desc: ["商品快捷创建", "批量上架、下架"],
    img: goodsImg,
  },
  {
    title: "多级价格",
    desc: ["多级商品价格", "一客一价", "自定义报价"],
    img: quoteImg,
  },
  {
    title: "客户/访客管理",
    desc: ["客户管理，一客一价", "访客转化", "黑名单客户"],
    img: clientsImg,
  },
  {
    title: "帮助中心",
    desc: ["帮助商品初始化", "专属客服指导"],
    img: helpImg,
  },
];

const NextScreen = () => {
  return (
    <div className="other-content">
      {service.map((item, index) => (
        <div className={`service ${index % 2 === 0 ? "right" : ""}`}>
          <div className="service-content">
            <h2>{item.title}</h2>
            {item.desc.map((text) => (
              <p>{text}</p>
            ))}
          </div>
          <div className="img-wrap">
            <img src={item.img} alt="" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default function HomePage() {
  return (
    <>
      <FirstScreen />
      <NextScreen />
    </>
  );
}
